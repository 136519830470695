@import "reset";
// @import "compass/css3";

@font-face {
  font-family: TSTARPROBold;
  src: url("../fonts/TSTARPRO-BoldWeb.eot");
}
@font-face {
  font-family: TSTARPROBold;
  src: url("../fonts/TSTARPRO-BoldWeb.woff") format("woff");
}
@font-face {
  font-family: TSTARPRORegular;
  src: url("../fonts/TSTARPRO-RegularWeb.eot");
}
@font-face {
  font-family: TSTARPRORegular;
  src: url("../fonts/TSTARPRO-RegularWeb.woff") format("woff");
}

body {
  text-align: left;
  font-family: TSTARPRORegular, Helvetica, Arial, sans-serif;
  color: #000;
  font-size: 13px;
  //border-bottom:8px solid #000;
  box-sizing: border-box;

  a:link,
  a:visited {
    color: #777;
    text-decoration: none;
  }
  a:hover {
    color: #000;
  }
}

body {
  height: 100%;
  min-width: 1024px;
}
html {
  min-height: 100%;
  position: relative;
}

.blackbar {
  width: 100%;
  height: 8px;
  background-color: #000;
  position: absolute;
  display: none;
}

.borderwrapper {
  height: 100%;
  width: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -20;
  border-bottom: 8px solid #000;
  box-sizing: border-box;
  min-width: 1024px;
}
.contactcloser {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: none;
}

.wrapper {
  padding-bottom: 190px;
  width: 1025px;
  margin: 0 auto;
  position: relative;

  @media screen and (min-width: 1265px) {
    width: 1265px;
  }
  @media screen and (min-width: 1515px) {
    width: 1515px;
  }
}

.clearBoth {
  clear: both;
}

.contactwrap {
  width: 100%;
  min-width: 1024px;
  height: 325px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  display: none;
  z-index: 5;

  .contactcontentwrap {
    width: 1025px;
    margin: 0 auto;

    @media screen and (min-width: 1265px) {
      width: 1265px;
    }
    @media screen and (min-width: 1515px) {
      width: 1515px;
    }

    .contact {
      margin-top: 135px;
      margin-left: 25px;

      .content {
        float: left;
        border-left: 1px solid #ccc;
        padding-left: 10px;
        margin-left: 10px;
        height: 190px;
        color: #a6a6a6;

        h1 {
          color: #a6a6a6;
          padding-bottom: 6px;
          float: none;
        }
        p {
          font-size: 24px;
          font-family: TSTARPRORegular, Helvetica, Arial, sans-serif;
          letter-spacing: 0.05em;
          float: left;
          line-height: 34px;

          a:link,
          a:visited {
            color: #a6a6a6;
            transition: 0.1s color;
          }
          a:hover {
            color: #fff;
          }
        }
      }

      h1 {
        color: #fff;

        font-size: 24px;
        font-family: TSTARPROBold, Helvetica, Arial, sans-serif;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        float: left;

        &.light {
          color: #ccc;

          a:link,
          a:visited {
            color: #ccc;
          }
          a:hover {
            color: #aaa;
          }
        }
      }
    }
  }
}

header {
  padding-top: 135px;
  margin-left: 25px;

  h1 {
    font-size: 24px;
    font-family: TSTARPROBold, Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    float: left;

    &.clearfilter {
      cursor: pointer;
    }

    &.light {
      color: #ccc;

      a:link,
      a:visited {
        color: #ccc;
      }
      a:hover {
        color: #aaa;
      }
    }
  }

  .navMain {
    float: left;
    border-left: 1px solid #ccc;
    padding-left: 10px;
    margin-left: 10px;

    .divider {
      height: 1px;
      background-color: #ccc;
      margin: 12px 0 15px 0;
    }

    ul.nav {
      list-style-type: none;
      margin-top: 40px;

      li {
        font-size: 13px;
        margin-top: 5px;
        text-transform: uppercase;
        font-family: TSTARPROBold, Helvetica, Arial, sans-serif;
        letter-spacing: 0.1em;

        a:link,
        a:visited {
          color: #b5b5b5;
          transition: 0.1s color;
        }
        a:hover,
        a.is-active {
          color: #000;
        }
        a.is-active:hover {
          color: #666;
        }
      }
    }

    .ateliertype {
      margin-top: 220px;
      width: 630px;

      ul.werkliste {
        li {
          margin-top: 12px;

          a.imglink {
            padding-left: 29px;
            margin-left: 10px;
            background-image: url("~/src/images/linkarrow.png");
            background-repeat: no-repeat;
            background-position: left 40%;
          }
        }
      }

      .pic {
        float: right;
        // margin-right: -230px;
        margin-left: 10px;
        margin-top: 6px;

        &.p2 {
          margin-top: -150px;
        }
        img {
          display: block;
        }
      }

      p {
        font-size: 13px;
        line-height: 26px;
        margin-bottom: 20px;
        width: 400px;

        &:last-child {
          margin-bottom: -9px;
        }
      }
    }
  }

  .navTop {
    position: absolute;
    top: 25px;
    right: 28px;
    z-index: 10;

    ul.nav {
      li {
        font-size: 13px;
        text-transform: uppercase;
        font-family: TSTARPROBold, Helvetica, Arial, sans-serif;
        letter-spacing: 0.1em;
        display: inline;
        margin-left: 20px;

        a:link,
        a:visited {
          color: #b5b5b5;
          transition: 0.1s color;
        }
        a:hover,
        a.is-active {
          color: #000;
        }
      }
    }
  }

  &.contactOpen .navTop ul.nav li {
    a:hover,
    a.is-active {
      color: #fff;
    }
  }
}

.controls {
  height: 154px;
  position: fixed;
  top: 30%;
  width: 100%;

  a.button {
    display: block;
    height: 154px;
    width: 30px;
    float: left;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.8);
    background-repeat: no-repeat;
    background-position: center 17px;
    transition: 0.1s background-color;

    &.right {
      float: right;
      background-image: url("~/src/images/arrow-right.png");
    }
    &.left {
      background-image: url("~/src/images/arrow-left.png");
    }
    &.close {
      float: left;
      margin-left: 2px;
      background-image: url("~/src/images/cross.png");
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.9);
    }
  }
  .info {
    width: 225px;
    background-color: rgba(0, 0, 0, 0.8);
    height: 154px;
    float: left;
    margin-left: 2px;
    color: #fff;
    font-family: TSTARPROBold, Helvetica, Arial, sans-serif;
    position: relative;
    font-size: 13px;
    overflow: hidden;

    span {
      position: absolute;
      left: 15px;
    }
    .title {
      text-transform: uppercase;
      top: 20px;
      letter-spacing: 0.1em;
    }
    .description {
      bottom: 56px;
    }
    .place {
      bottom: 38px;
    }
    .year {
      bottom: 20px;
    }
  }
}
.showcase {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: -10;
  overflow: hidden;

  .pic {
    height: 100%;
    width: 100%;
    background-color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    display: none;

    .picbig {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: 0;
      left: 0;
    }

    &.vertical {
      background-size: auto 100%;
      background-position: 57% 0;

      .picbig {
        background-size: auto 100%;
        background-position: 57% 0;
      }
    }
    &.blueprint {
      background-size: contain;

      .picbig {
        background-size: contain;
      }
    }
  }
}

.gallery {
  margin: 40px 15px;

  .project {
    float: left;
    width: 225px;
    height: 155px;
    border: 1px solid #838383;
    background-size: 100% auto;
    background-position: center center;
    margin: 10px;
    transition: 0.1s opacity;

    &.hidden {
      opacity: 0.2;
    }

    .info {
      display: block;
      background-color: rgba(0, 0, 0, 0.8);
      height: 100%;
      color: #fff;
      font-family: TSTARPROBold, Helvetica, Arial, sans-serif;
      position: relative;
      opacity: 0;
      transition: 0.1s opacity;

      &:hover {
        opacity: 1;
      }
      .title {
        text-transform: uppercase;
        font-family: TSTARPROBold, Helvetica, Arial, sans-serif;
        position: absolute;
        top: 25px;
        left: 15px;
        letter-spacing: 0.1em;
      }
      .date {
        position: absolute;
        bottom: 25px;
        left: 15px;
      }
      .place {
        position: absolute;
        bottom: 45px;
        left: 15px;
      }
    }
  }
}
