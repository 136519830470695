html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: TSTARPROBold;
  src: url("../TSTARPRO-BoldWeb.17738776.eot");
}

@font-face {
  font-family: TSTARPROBold;
  src: url("../TSTARPRO-BoldWeb.4d46fd46.woff") format("woff");
}

@font-face {
  font-family: TSTARPRORegular;
  src: url("../TSTARPRO-RegularWeb.12d49ad3.eot");
}

@font-face {
  font-family: TSTARPRORegular;
  src: url("../TSTARPRO-RegularWeb.e83039cc.woff") format("woff");
}

body {
  text-align: left;
  color: #000;
  box-sizing: border-box;
  font-family: TSTARPRORegular, Helvetica, Arial, sans-serif;
  font-size: 13px;
}

body a:link, body a:visited {
  color: #777;
  text-decoration: none;
}

body a:hover {
  color: #000;
}

body {
  height: 100%;
  min-width: 1024px;
}

html {
  min-height: 100%;
  position: relative;
}

.blackbar {
  width: 100%;
  height: 8px;
  background-color: #000;
  display: none;
  position: absolute;
}

.borderwrapper {
  height: 100%;
  width: 100%;
  z-index: -20;
  box-sizing: border-box;
  min-width: 1024px;
  background-color: #fff;
  border-bottom: 8px solid #000;
  position: absolute;
  top: 0;
  left: 0;
}

.contactcloser {
  height: 100%;
  width: 100%;
  z-index: 4;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.wrapper {
  width: 1025px;
  margin: 0 auto;
  padding-bottom: 190px;
  position: relative;
}

@media screen and (min-width: 1265px) {
  .wrapper {
    width: 1265px;
  }
}

@media screen and (min-width: 1515px) {
  .wrapper {
    width: 1515px;
  }
}

.clearBoth {
  clear: both;
}

.contactwrap {
  width: 100%;
  min-width: 1024px;
  height: 325px;
  z-index: 5;
  background-color: #000;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.contactwrap .contactcontentwrap {
  width: 1025px;
  margin: 0 auto;
}

@media screen and (min-width: 1265px) {
  .contactwrap .contactcontentwrap {
    width: 1265px;
  }
}

@media screen and (min-width: 1515px) {
  .contactwrap .contactcontentwrap {
    width: 1515px;
  }
}

.contactwrap .contactcontentwrap .contact {
  margin-top: 135px;
  margin-left: 25px;
}

.contactwrap .contactcontentwrap .contact .content {
  float: left;
  height: 190px;
  color: #a6a6a6;
  border-left: 1px solid #ccc;
  margin-left: 10px;
  padding-left: 10px;
}

.contactwrap .contactcontentwrap .contact .content h1 {
  color: #a6a6a6;
  float: none;
  padding-bottom: 6px;
}

.contactwrap .contactcontentwrap .contact .content p {
  letter-spacing: .05em;
  float: left;
  font-family: TSTARPRORegular, Helvetica, Arial, sans-serif;
  font-size: 24px;
  line-height: 34px;
}

.contactwrap .contactcontentwrap .contact .content p a:link, .contactwrap .contactcontentwrap .contact .content p a:visited {
  color: #a6a6a6;
  transition: color .1s;
}

.contactwrap .contactcontentwrap .contact .content p a:hover {
  color: #fff;
}

.contactwrap .contactcontentwrap .contact h1 {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .05em;
  float: left;
  font-family: TSTARPROBold, Helvetica, Arial, sans-serif;
  font-size: 24px;
}

.contactwrap .contactcontentwrap .contact h1.light, .contactwrap .contactcontentwrap .contact h1.light a:link, .contactwrap .contactcontentwrap .contact h1.light a:visited {
  color: #ccc;
}

.contactwrap .contactcontentwrap .contact h1.light a:hover {
  color: #aaa;
}

header {
  margin-left: 25px;
  padding-top: 135px;
}

header h1 {
  text-transform: uppercase;
  letter-spacing: .05em;
  float: left;
  font-family: TSTARPROBold, Helvetica, Arial, sans-serif;
  font-size: 24px;
}

header h1.clearfilter {
  cursor: pointer;
}

header h1.light, header h1.light a:link, header h1.light a:visited {
  color: #ccc;
}

header h1.light a:hover {
  color: #aaa;
}

header .navMain {
  float: left;
  border-left: 1px solid #ccc;
  margin-left: 10px;
  padding-left: 10px;
}

header .navMain .divider {
  height: 1px;
  background-color: #ccc;
  margin: 12px 0 15px;
}

header .navMain ul.nav {
  margin-top: 40px;
  list-style-type: none;
}

header .navMain ul.nav li {
  text-transform: uppercase;
  letter-spacing: .1em;
  margin-top: 5px;
  font-family: TSTARPROBold, Helvetica, Arial, sans-serif;
  font-size: 13px;
}

header .navMain ul.nav li a:link, header .navMain ul.nav li a:visited {
  color: #b5b5b5;
  transition: color .1s;
}

header .navMain ul.nav li a:hover, header .navMain ul.nav li a.is-active {
  color: #000;
}

header .navMain ul.nav li a.is-active:hover {
  color: #666;
}

header .navMain .ateliertype {
  width: 630px;
  margin-top: 220px;
}

header .navMain .ateliertype ul.werkliste li {
  margin-top: 12px;
}

header .navMain .ateliertype ul.werkliste li a.imglink {
  background-image: url("../linkarrow.9f204a60.png");
  background-position: 0 40%;
  background-repeat: no-repeat;
  margin-left: 10px;
  padding-left: 29px;
}

header .navMain .ateliertype .pic {
  float: right;
  margin-top: 6px;
  margin-left: 10px;
}

header .navMain .ateliertype .pic.p2 {
  margin-top: -150px;
}

header .navMain .ateliertype .pic img {
  display: block;
}

header .navMain .ateliertype p {
  width: 400px;
  margin-bottom: 20px;
  font-size: 13px;
  line-height: 26px;
}

header .navMain .ateliertype p:last-child {
  margin-bottom: -9px;
}

header .navTop {
  z-index: 10;
  position: absolute;
  top: 25px;
  right: 28px;
}

header .navTop ul.nav li {
  text-transform: uppercase;
  letter-spacing: .1em;
  margin-left: 20px;
  font-family: TSTARPROBold, Helvetica, Arial, sans-serif;
  font-size: 13px;
  display: inline;
}

header .navTop ul.nav li a:link, header .navTop ul.nav li a:visited {
  color: #b5b5b5;
  transition: color .1s;
}

header .navTop ul.nav li a:hover, header .navTop ul.nav li a.is-active {
  color: #000;
}

header.contactOpen .navTop ul.nav li a:hover, header.contactOpen .navTop ul.nav li a.is-active {
  color: #fff;
}

.controls {
  height: 154px;
  width: 100%;
  position: fixed;
  top: 30%;
}

.controls a.button {
  height: 154px;
  width: 30px;
  float: left;
  background-color: #000c;
  background-position: 50% 17px;
  background-repeat: no-repeat;
  transition: background-color .1s;
  display: block;
}

.controls a.button.right {
  float: right;
  background-image: url("../arrow-right.4871d622.png");
}

.controls a.button.left {
  background-image: url("../arrow-left.6b0d68d0.png");
}

.controls a.button.close {
  float: left;
  background-image: url("../cross.1504b53d.png");
  margin-left: 2px;
}

.controls a.button:hover {
  background-color: #000000e6;
}

.controls .info {
  width: 225px;
  height: 154px;
  float: left;
  color: #fff;
  background-color: #000c;
  margin-left: 2px;
  font-family: TSTARPROBold, Helvetica, Arial, sans-serif;
  font-size: 13px;
  position: relative;
  overflow: hidden;
}

.controls .info span {
  position: absolute;
  left: 15px;
}

.controls .info .title {
  text-transform: uppercase;
  letter-spacing: .1em;
  top: 20px;
}

.controls .info .description {
  bottom: 56px;
}

.controls .info .place {
  bottom: 38px;
}

.controls .info .year {
  bottom: 20px;
}

.showcase {
  height: 100%;
  width: 100%;
  z-index: -10;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.showcase .pic {
  height: 100%;
  width: 100%;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.showcase .pic .picbig {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.showcase .pic.vertical, .showcase .pic.vertical .picbig {
  background-position: 57% 0;
  background-size: auto 100%;
}

.showcase .pic.blueprint, .showcase .pic.blueprint .picbig {
  background-size: contain;
}

.gallery {
  margin: 40px 15px;
}

.gallery .project {
  float: left;
  width: 225px;
  height: 155px;
  background-position: center;
  background-size: 100%;
  border: 1px solid #838383;
  margin: 10px;
  transition: opacity .1s;
}

.gallery .project.hidden {
  opacity: .2;
}

.gallery .project .info {
  height: 100%;
  color: #fff;
  opacity: 0;
  background-color: #000c;
  font-family: TSTARPROBold, Helvetica, Arial, sans-serif;
  transition: opacity .1s;
  display: block;
  position: relative;
}

.gallery .project .info:hover {
  opacity: 1;
}

.gallery .project .info .title {
  text-transform: uppercase;
  letter-spacing: .1em;
  font-family: TSTARPROBold, Helvetica, Arial, sans-serif;
  position: absolute;
  top: 25px;
  left: 15px;
}

.gallery .project .info .date {
  position: absolute;
  bottom: 25px;
  left: 15px;
}

.gallery .project .info .place {
  position: absolute;
  bottom: 45px;
  left: 15px;
}

/*# sourceMappingURL=style.css.map */
